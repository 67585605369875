var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ordernes mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Órdenes de compra ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"footer-props":{
                  itemsPerPageText: 'Órdenes',
                  itemsPerPageOptions: [10, 25, 50, -1],
                },"options":{
                  itemsPerPage: 25,
                },"loading":_vm.loading_t,"items":_vm.lista},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){return _vm.$router.push('/orden')}}},[_vm._v(" Crear ")])],1)]},proxy:true},{key:"item.total",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.estado",fn:function(ref){
                var item = ref.item;
return [(item.estado == 'Pendiente')?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(" "+_vm._s(item.estado)+" ")]):_vm._e(),(item.estado == 'Cargada')?_c('v-chip',{attrs:{"color":"success","dark":""}},[_vm._v(" "+_vm._s(item.estado)+" ")]):_vm._e(),(item.estado == 'Anulada')?_c('v-chip',{attrs:{"color":"error","dark":""}},[_vm._v(" "+_vm._s(item.estado)+" ")]):_vm._e()]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/detalleOrden/' + item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver orden")])])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }