<template>
  <div class="ordernes mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Órdenes de compra
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :search="search"
                  :headers="headers"
                  :footer-props="{
                    itemsPerPageText: 'Órdenes',
                    itemsPerPageOptions: [10, 25, 50, -1],
                  }"
                  :options="{
                    itemsPerPage: 25,
                  }"
                  :loading="loading_t"
                  :items="lista"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-col cols="4" class="px-0">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          class="mt-6"
                          v-model="search"
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-btn dark color="black" @click="$router.push('/orden')">
                        Crear
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                  <template v-slot:item.estado="{ item }">
                    <v-chip
                      color="orange"
                      dark
                      v-if="item.estado == 'Pendiente'"
                    >
                      {{ item.estado }}
                    </v-chip>
                    <v-chip
                      color="success"
                      dark
                      v-if="item.estado == 'Cargada'"
                    >
                      {{ item.estado }}
                    </v-chip>
                    <v-chip color="error" dark v-if="item.estado == 'Anulada'">
                      {{ item.estado }}
                    </v-chip>
                  </template>
                  <template v-slot:item.acciones="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          @click="$router.push('/detalleOrden/' + item.id)"
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver orden</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "ordenes",
  data: () => ({
    search: "",
    loading_t: false,
    headers: [
      {
        text: "Fecha creación",
        value: "created_at",
        align: "start",
      },
      {
        text: "N° Orden",
        value: "folio",
      },
      {
        text: "N° factura",
        value: "factura",
      },
      {
        text: "Total",
        value: "total",
      },
      {
        text: "Proveedor",
        value: "proveedor",
      },
      {
        text: "Estado",
        value: "estado",
      },
      {
        text: "Pago",
        value: "metodo",
      },
      {
        text: "",
        value: "acciones",
      },
    ],
    ordenes: [],
  }),
  methods: {
    loadOrdenes() {
      this.loading_t = true;
      const body = {
        route: "/ordenes",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.ordenes = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
  },
  created() {
    this.loadOrdenes();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      return this.ordenes.map((orden) => {
        let data = orden;
        data.created_at = moment(orden.created_at).format("ll");
        data.folio = this.$options.filters.folio(orden.folio);
        data.factura =
          orden.factura == null
            ? "----"
            : this.$options.filters.folio(orden.factura);
        if (parseInt(orden.estado) == 0) {
          data.estado = "Pendiente";
        } else if (parseInt(orden.estado) == 1) {
          data.estado = "Cargada";
        } else if (parseInt(orden.estado) == 2) {
          data.estado = "Anulada";
        }
        data.metodo = orden.metodo == 1 ? "CONTADO" : "CREDITO";
        return data;
      });
    },
  },
};
</script>
